/*!
 * Copyright 2025 National Association of Insurance Commissioners
 */

/* You can add global styles to this file, and also import other style files */
body, html {
  height: 100%;
  margin: 0;
}

.card-header {
  background-color: rgba(0, 0, 0, .04);
}

.card-footer {
  background-color: rgba(0, 0, 0, .04);
}

.btn.btn-primary {
  --bs-btn-bg: #2c6ba0;
  --bs-btn-border-color: #275e8c;
  --bs-btn-color: #fff;
  --bs-btn-hover-bg: #275e8c;
  --bs-btn-hover-border-color: #224f77;
  --bs-btn-hover-color: #fff;
}

.clickable {
  cursor: pointer;
}
